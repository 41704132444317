import Seo from "../../components/seo";
import Layout from "../../components/layout"
import React, {useState} from "react";
import {Divider, Button} from 'antd';
import iconImg from '../../assets/images/download/ellipsis.png'
import arrowImg from '../../assets/images/download/arrow.png'
import './index.scss'
import {BuryingPointFc} from "../../components/buryingPoint";
import pathJson from "../../data/common/path.json"

const MerchantCenter = () => {
    const [overCard, setOverCard] = useState(false)
    const wxFc = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isWechat = userAgent.includes('micromessenger');

        if (isWechat) {
            setOverCard(true)
        } else {
            window.open('https://wepro.oss-cn-shenzhen.aliyuncs.com/android/LDREC_Android/1.6.5/ldsns_v1.6.5_release_0321.apk');
        }
    }
    return (
        <Layout>
            <Seo title={pathJson.DOWNLOAD.title}/>
            {
                overCard &&
                <div onClick={() => setOverCard(false)} style={{
                    zIndex: '99',
                    top: '0px',
                    opacity: '.7',
                    overflow: 'hidden',
                    background: 'black',
                    position: 'fixed',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                }}>

                </div>

            }
            {
                overCard && <img className="modalImg" src={arrowImg}/>
            }

            {
                overCard &&
                <div className="modal" style={{}}>

                    <div style={{textAlign: 'center'}}>
                        点击右上角 <img color="" src={iconImg} style={{width: '10%'}}/>
                    </div>
                    <p>选择在浏览器中打开</p>
                </div>
            }
            <div style={{height: '670px', textAlign: 'center', padding: '40px 0px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p>里德笔记</p>
                    <h3>下载中心</h3>
                </div>
                <Divider/>
                <div className="appText">
                    <div>
                        <h3>移动端安卓版</h3>
                        <p>Android App</p>
                        <p>当前版本：1.6.5</p>
                    </div>
                    <div>
                        <h5>系统</h5>
                        <p style={{color: '#333'}}>安卓5.0以上</p>
                    </div>
                    <div>
                        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                            {/* <img src="../../assets/images/download/download.png" alt="下载二维码" /> */}
                            <div className="imgBox"></div>
                            <span style={{color: '#6c757d'}}>用手机“浏览器”扫描下载</span>
                        </div>
                        <Button onClick={() => {
                            wxFc();
                            BuryingPointFc('VO', 'VO00300100400101', {action: '点击里德app下载-立即下载'})
                        }} style={{marginTop: '15px', width: "200px", height: '40px'}}>立即下载</Button>
                    </div>

                </div>
            </div>
        </Layout>
    )
}
export default MerchantCenter
export const Head = () => <title>自主运营 | 里德笔记</title>